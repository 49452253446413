import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { toggleMenu } from '../../actions/appActions';

import { ReactComponent as Close } from '../../assets/cross.svg';
import './Menu.css';

const Menu = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.auth);
  const appSelector = useSelector((state) => state.app);
  const { user } = authSelector;
  const { menuOpen, menu } = appSelector;

  return (
    <div className={`sidemenu ${menuOpen ? 'menuOpen' : 'menuClose'}`}>
      <div>
        <button
          className='sidemenu__close'
          onClick={() => dispatch(toggleMenu())}
          aria-label='Close menu'
        >
          <Close style={{ width: '30px', height: '30px' }} />
        </button>
      </div>
      <ul className='sidemenu__links'>
        {menu && (
          <Fragment>
            {menu.map((item) => (
              <li
                className={`sidemenu__link ${
                  location.pathname === item.path ? 'active' : ''
                }`}
                key={item.id}
              >
                <Link to={item.path}>{item.label}</Link>
              </li>
            ))}
          </Fragment>
        )}
      </ul>
      <ul className='sidemenu__links'>
        {user && (
          <li className='sidemenu__link'>
            <Link to='/logout'>Sign out</Link>
          </li>
        )}
      </ul>
      <div style={{ marginTop: '1rem' }}>Version 1.0.13</div>
    </div>
  );
};

export default Menu;
